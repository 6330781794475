import React from "react";

const ProfileCol = (props) => {
  const { usuario, nombre, telefono } = props.data;
  // const { usuario, nombre, telefono, propsact, propsreg } = props.data;

  const handleUpdateUser = () => {
    props.onUpdate(null);
  };

  const handleUpdateUserFiscales = () => {
    props.onUpdateFiscales(null);
  };

  // const handleCartera = () => {
  //   props.onCartera();
  // };

  const handleModal = () => {
    props.modal();
  };

  // const handleContacto = () => {
  //   props.onContacto();
  // };

  return (
    <React.Fragment>
      <div className="card mt-4" style={{}}>
        <div className="card-header text-center">
          <img
            src={
              process.env.REACT_APP_CLOUDINARY +
              "image/upload/c_scale,h_100,w_100/v1596750089/Landzilla/Web/default-avatar_ive6sy.png"
            }
            alt="Profile"
            className="img-circle img-no-padding img-responsive"
          />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center">
            <b>{nombre}</b>
          </h5>
          <ul className="pt-3" style={{ fontWeight: "bold" }}>
            <li>Usuario: {usuario}</li>
            <li>Telefono: {telefono}</li>
          </ul>
        </div>
      </div>
      <div className="card mt-4" style={{}}>
        <div className="card-body">
          <h5 className="card-title text-center">
            <b>
              Operaciones <i className="fa fa-cog" aria-hidden="true"></i>
            </b>
          </h5>
          <ul className="pt-3" style={{ fontWeight: "bold" }}>
            <li>
              <a href="#lz" onClick={handleUpdateUser} className="text-danger">
                <h5>
                  <b>
                    Modificar Datos
                  </b>
                </h5>
              </a>
            </li>
            <li>
              <a href="#lz" onClick={handleUpdateUserFiscales} className="text-danger">
                <h5>
                  <b>
                    Actualizar Datos Fiscales
                  </b>
                </h5>
              </a>
            </li>
            <li>
              <a href="#lz" onClick={handleModal} className="text-danger">
                <h5>
                  <b>Cambiar Contraseña</b>
                </h5>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileCol;
