import React, { Component } from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import wNumb from "wnumb";
import IndexNavbar from "./indexNavbar";
import DoughnutChart from "./common/doughnutChart";
import LinearChart from "./common/linearChart";
import RadarChart from "./common/radarChart";
import {
  PremiumMap,
  drawPremium,
  colorLayer,
  clearLayer,
  mapUpdate,
  markerPremium,
  colorMarker,
} from "./common/premiumMap";
import { getPoints } from "../services/playgroundService";

class Playground extends Component {
  state = { loadinfo: false, layer: "clear", colors: [], data: [], marker: 0 };

  componentDidMount = () => {
    Swal.fire(
      "Bienvenido al Playground",
      "Da click en CARGAR PINES y haz zoom para ver los resultados",
      "info"
    );
  };

  loadInformation = async () => {
    try {
      const { data } = await getPoints();
      if (data.length >= 1) {
        this.addMarkerInfo(data);
      } else {
        Swal.fire(
          "Error en carga de información",
          "Envianos un correo a info@capitolcity2.com para reportar este error",
          "error"
        );
      }
    } catch (e) {
      Swal.fire(
        "Error en carga de información",
        "Envianos un correo a info@capitolcity2.com para reportar este error",
        "error"
      );
    }
  };

  addMarkerInfo = async (info) => {
    let data = [];
    // console.log(info);
    const route = process.env.REACT_APP_GH_SHP + "Playground/";
    for (let i = 0; i < info.length; i++) {
      /* Agregar el marcador */
      const coords = { lat: info[i].lat, lng: info[i].lng };
      const { marker, mapas } = markerPremium(coords, 2, 0, 0);
      let markerContent = document.createElement("div");
        markerContent.style.width = "32px";
        markerContent.style.height = "32px";
        markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/red-dot.png')";
        markerContent.style.backgroundSize = "cover";
      marker.metadata = { id: i, status: 2 };
      mapas.event.addListener(marker, "click", (e) => {
        this.markerUpdate(marker.metadata.id);
        // marker.setIcon("https://maps.google.com/mapfiles/ms/icons/red-dot.png");
        marker.content = markerContent
        colorMarker(marker.metadata.id);
      });
      if (i === 0) {
        marker.content = markerContent
        // marker.setIcon("https://maps.google.com/mapfiles/ms/icons/red-dot.png");
      }
      /* Agregar el marcador */
      /* Variables para totales */
      let pobTotal = 0;
      let vivTotal = 0;
      let pobLocal = 0;
      let pobFor = 0;
      let pobTotHab = 0;
      let vivHab = 0;
      let ocuHab = 0;
      let ocuAvg = 0;
      let educacion = 0;
      let recreativo = 0;
      let laboral = 0;
      let nb = 0;
      let salud = 0;
      let nse = 0;
      let nseText = "NA";
      let countnse = 0;
      let km2 = 1;
      let totalTraf = 0;
      let trafico = info[i].trafico
        ? info[i].trafico.split(",").map(Number)
        : [];
      /* Variables para totales */
      /* Validar si esta completo */
      await fetch(route + info[i].archivo)
        .then((response) => response.json())
        .then((jsonData) => {
          //console.log(jsonData);
          /* Extraer datos */
          drawPremium(jsonData);
          const len = jsonData.features.length;
          let feat = [];
          for (let x = 0; x < len; x++) {
            feat = jsonData.features[x];
            pobTotal += feat.properties.Demo_POBTOT;
            vivTotal += feat.properties.Demo_TVIVPAR;
            pobLocal += feat.properties.Demo_PNACENT;
            pobFor += feat.properties.Demo_PNACOE;
            vivHab += feat.properties.Demo_VIVPAR_HAB;
            ocuHab += feat.properties.Demo_OCUPVIVPAR;
            educacion += feat.properties.Radar_Educacion;
            recreativo += feat.properties.Radar_Recreativo;
            laboral += feat.properties.Radar_Laboral;
            nb += feat.properties.Radar_NB;
            salud += feat.properties.Radar_Salud;
            km2 += feat.properties.Km2;
            nse += feat.properties.Data_NSE;
            countnse = feat.properties.Data_NSE > 0 ? countnse + 1 : countnse;
          }
          pobTotHab = pobLocal + pobFor;
          pobLocal = pobLocal / pobTotHab;
          pobFor = pobFor / pobTotHab;
          ocuAvg = parseFloat((ocuHab / vivHab).toFixed(2));

          for (let z = 0; z < trafico.length; z++) {
            totalTraf += trafico[z] * 14;
          }

          nse = nse / countnse;
          /* Extraer datos */
        })
        .catch((error) => {
          console.error(error);
        });
      /* Validar si esta completo */
      km2 = km2 === 1 ? 1 : parseFloat(km2.toFixed(2));
      nseText =
        nse > 0
          ? nse <= 2
            ? "Alto"
            : nse > 2 && nse <= 3
            ? "Medio-Alto"
            : nse > 3 && nse <= 4
            ? "Medio-Bajo"
            : "Bajo"
          : "NA";
      /* Datos de flujo */
      let total_flujo = 0;
      let trafico_sem = info[i].t_semana.split(",").map(Number);
      let con_sem = info[i].c_horas.split(",").map(Number);
      let con_fin = info[i].c_horas_fin.split(",").map(Number);

      for (let i = 0; i < trafico_sem.length; i++) {
        total_flujo += trafico_sem[i] * 14;
      }
      /* Insertar en el arreglo data */
      data.push({
        pobTotal,
        vivTotal,
        pobLocal,
        pobFor,
        ocuAvg,
        kvar: [
          parseFloat(laboral / km2 / 2).toFixed(2),
          parseFloat(salud / km2 / 8).toFixed(2),
          parseFloat(educacion / km2 / 8).toFixed(2),
          parseFloat(recreativo / km2 / 4).toFixed(2),
          parseFloat(nb / km2 / 4).toFixed(2),
        ],
        trafico,
        totalTraf,
        km2,
        nseText,
        ciudad: info[i].ciudad,
        calle: info[i].calle,
        titulo: info[i].titulo,
        t_semana: trafico_sem,
        c_horas: con_sem,
        c_horas_fin: con_fin,
        flujo_total: total_flujo,
        velocidad: info[i].velocidad,
        congestion: parseFloat(info[i].congestion * 100).toFixed(2),
      });
      /* Insertar en el arreglo data */
    } // Termina el for
    this.setState({ loadinfo: true, data });
  };

  markerUpdate = (marker) => {
    this.setState({ marker: marker });
  };

  handleMap = ({ currentTarget: input }) => {
    const map = input.value;
    mapUpdate(map);
  };

  handleLayer = ({ currentTarget: input }) => {
    const layer = input.value;
    let arrayColors = [];
    let limits = [];
    let field = "";
    switch (layer) {
      case "nse":
        arrayColors = ["#2c7bb6", "#abd9e9", "#ffffbf", "#fdae61", "#d7191c"];
        limits = [2, 3, 4, 5];
        field = "Data_NSE";
        break;
      case "dpob":
        arrayColors = ["#fee5d9", "#fcae91", "#fb6a4a", "#cb181d"];
        limits = [4240, 10930, 21410];
        field = "Densidad_Pob";
        break;
      case "dviv":
        arrayColors = ["#feebe2", "#fbb4b9", "#f768a1", "#ae017e"];
        limits = [1150, 2900, 5500];
        field = "Densidad_Viv";
        break;
      case "young":
        arrayColors = ["#ffffcc", "#a1dab4", "#41b6c4", "#2c7fb8", "#253494"];
        limits = [0.05, 0.11, 0.17, 0.33];
        field = "Jovenes";
        break;
      case "old":
        arrayColors = ["#ffffd4", "#fed98e", "#fe9929", "#d95f0e", "#993404"];
        limits = [0.04, 0.09, 0.17, 0.42];
        field = "Mayores";
        break;
      case "salud":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [3, 6, 9, 12];
        field = "Radar_Salud";
        break;
      case "recreativo":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [2, 5, 7, 9];
        field = "Radar_Recreativo";
        break;
      case "laboral":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [3, 6, 9, 12];
        field = "Radar_Laboral";
        break;
      case "educacion":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [3, 6, 9, 12];
        field = "Radar_Educacion";
        break;
      case "nb":
        arrayColors = ["#f2f0f7", "#cbc9e2", "#9e9ac8", "#756bb1", "#54278f"];
        limits = [2, 5, 7, 9];
        field = "Radar_NB";
        break;
      default:
        clearLayer();
    }
    if (layer !== "clear") {
      colorLayer(arrayColors, limits, field);
    }
    let colors = arrayColors;
    colors = layer === "nse" ? colors : colors.slice().reverse();
    this.setState({ colors, layer });
  };

  handleHelp = () => {
    Swal.fire({
      title: "Detalles de métricas",
      html:
        "<ul style='text-align:justify'><li><p><b><b>Tráfico:</b> Cantidad de vehículos que pasan regularmente enfrente del punto por semana</b></p></li><li><p><b><b>Congestión:</b> Muestra el porcentaje de ocupación del tramo en diferentes horas durante el día, entre más alto mayor congestionamiento vial. Entre menor congestión, mayor flujo vehicular</b></p></li><li><p><b><b>Composición del Área:</b> Índice que muestra la concentración de factores en el área:<ul style='text-align: left;'><li><p><b>Laboral:</b> Empresas de Manufactura, Corporativos, Oficinas</p></li><li><p><b>Salud:</b> Hospitales, Farmacias, Consultorios, Dentisas etc.</p></li><li><p><b>Educación:</b> Primarias, Secundarias, Preparatorias etc.</p></li><li><p><b>Recreativo:</b> Restaurantes, Plazas Comerciales, Tiendas Departamentales</p></li><li><p><b>Abarrotes:</b> Supermercados, OXXO, Seven</p></li></ul></b></p></li></ul>",
      icon: "info",
    });
  };

  render() {
    const { loadinfo, data, colors, marker } = this.state;
    const commaFormat = wNumb({
      thousand: ",",
    });
    const donaPob = data.length
      ? JSON.parse(JSON.stringify([data[marker].pobLocal, data[marker].pobFor]))
      : [];
    const radarSect = data.length
      ? JSON.parse(JSON.stringify(data[marker].kvar))
      : [];

    const dataset = [
      {
        data: data.length
          ? JSON.parse(JSON.stringify(data[marker].t_semana))
          : [],
        borderColor: "rgba(255, 86, 0, 1)",
        pointBackgroundColor: "rgba(255, 86, 0, 1)",
        fill: false,
      },
    ];
    const dataset_con = [
      {
        label: "Entre Semana",
        data: data.length
          ? JSON.parse(JSON.stringify(data[marker].c_horas))
          : [],
        borderColor: "#12A5FF",
        pointBackgroundColor: "#12A5FF",
        fill: false,
      },
      {
        label: "Fin de Semana",
        data: data.length
          ? JSON.parse(JSON.stringify(data[marker].c_horas_fin))
          : [],
        borderColor: "rgba(0, 17, 28, 1)",
        pointBackgroundColor: "rgba(0, 17, 28, 1)",
        fill: false,
      },
    ];
    let minLine = data.length ? _.min(data[marker].t_semana) : 0;
    let maxLine = data.length ? _.max(data[marker].t_semana) : 0;
    let stepSize = data.length
      ? parseInt(
          (_.max(data[marker].t_semana) - _.min(data[marker].t_semana)) / 3
        )
      : 1;
    stepSize = Math.ceil(stepSize / 10) * 10;
    minLine = Math.floor(minLine / 10) * 10 - stepSize;
    maxLine = Math.ceil(maxLine / 10) * 10 + stepSize;
    minLine = minLine < 0 ? 0 : minLine;
    let maxArr = [];
    let minArr = [];
    if (data.length) {
      maxArr.push(_.max(data[marker].c_horas));
      maxArr.push(_.max(data[marker].c_horas_fin));
      minArr.push(_.min(data[marker].c_horas));
      minArr.push(_.min(data[marker].c_horas_fin));
    }
    let stepSizeCon = data.length ? (_.max(maxArr) - _.min(minArr)) / 3 : 1;
    let maxLineCon = _.max(maxArr) + stepSizeCon;
    let minLineCon =
      _.min(minArr) - stepSizeCon < 0 ? 0 : _.min(minArr) - stepSizeCon;

    return (
      <React.Fragment>
        <IndexNavbar
          clase="sticky-top"
          user={this.props.user}
          page={this.props.match.path}
        />
        <div className="row mt-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col text-center">
                <span>
                  <b>
                    Carga los pines y da click en alguno para ver
                    resultados&nbsp;
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </b>
                </span>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  className={loadinfo ? "text-inactive" : "text-danger"}
                  onClick={loadinfo ? null : this.loadInformation}
                >
                  <b>
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                    Cargar Pines
                  </b>
                </span>
                <span>
                  <b>
                    <i className="fa fa-arrow-left pl-1" aria-hidden="true"></i>
                  </b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col text-center">
                <span>
                  <b>{loadinfo ? data[marker].titulo : "Prueba Playground"}</b>
                </span>
                <i
                  className="fa fa-info-circle ml-1 text-danger align-self-center"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={this.handleHelp}
                ></i>
              </div>
            </div>
            <div className="row py-1">
              <div className="col-md-6 text-center">
                <span>
                  <b>Ciudad: {loadinfo ? data[marker].ciudad : ""}</b>
                </span>
              </div>
              <div className="col-md-6 text-center">
                <span>
                  <b>Calle: {loadinfo ? data[marker].calle : ""}</b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col-md-4 text-center">
                <span>
                  <b>
                    Población:{" "}
                    {loadinfo ? commaFormat.to(data[marker].pobTotal) : 0}
                  </b>
                </span>
              </div>
              <div className="col-md-4 text-center">
                <span>
                  <b>
                    Viviendas:{" "}
                    {loadinfo ? commaFormat.to(data[marker].vivTotal) : 0}
                  </b>
                </span>
              </div>
              <div className="col-md-4 text-center">
                <span>
                  <b>NSE: {loadinfo ? data[marker].nseText : ""}</b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col-md-4 text-center">
                <span>
                  <b>
                    Tráfico Semanal:{" "}
                    {loadinfo ? commaFormat.to(data[marker].flujo_total) : 0}
                  </b>
                </span>
              </div>
              <div className="col-md-4 text-center">
                <span>
                  <b>
                    Velocidad: {loadinfo ? data[marker].velocidad : 0} Km/hr
                  </b>
                </span>
              </div>
              <div className="col-md-4 text-center">
                <span>
                  <b>Congestion: {loadinfo ? data[marker].congestion : 0}%</b>
                </span>
              </div>
            </div>
            <div className="row py-1">
              <div className="col text-center">
                <small>
                  <b>*La información es parte del Playground y está limitada</b>
                </small>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-6 text-center">
                <div className="form-group">
                  <label>
                    <b>Mapa</b>
                  </label>
                  <select className="form-control" onChange={this.handleMap}>
                    <option value="calles">Calles</option>
                    <option value="hibrido">Hibrido</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="form-group">
                  <label>
                    <b>Visualización</b>
                    <span>
                      <b>
                        {" "}
                        {colors.length ? (
                          <React.Fragment>
                            {" "}
                            <span className="px-2">Alto</span>
                            <span>
                              {colors.map((color, i) => {
                                return (
                                  <i
                                    key={i}
                                    className="fa fa-square fa-lg"
                                    aria-hidden="true"
                                    style={{ color: color }}
                                  />
                                );
                              })}
                            </span>
                            <span className="p-2">Bajo</span>{" "}
                          </React.Fragment>
                        ) : null}
                      </b>
                    </span>
                  </label>
                  <select
                    className="form-control"
                    onChange={this.handleLayer}
                    disabled={!loadinfo}
                  >
                    <option value="clear">No Mostar Capa</option>
                    <option value="nse">Nivel Socioeconómico</option>
                    <option value="dpob">Densidad Poblacional</option>
                    <option value="dviv">Densidad Viviendas</option>
                    <option value="salud">Sector Salud</option>
                    <option value="laboral">Sector Laboral</option>
                    <option value="recreativo">Sector Recreativo</option>
                    <option value="educacion">Sector Educación</option>
                    <option value="nb">Sector Supermercados</option>
                    <option value="young">{"Población < 25 Años"}</option>
                    <option value="old">{"Población > 60 Años"}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <DoughnutChart
                  data={donaPob}
                  colors={[
                    "rgba(255, 86, 0, 1)",
                    "rgba(18, 165, 255, 1),rgba(18, 165, 255, 1)",
                  ]}
                  labels={["Locales", "Foraneos"]}
                  title={"Distribución Poblacional"}
                />
              </div>
              <div className="col-md-6 justify-content-center">
                <RadarChart
                  data={radarSect}
                  color={"18, 165, 255"}
                  labels={[
                    "Laboral",
                    "Salud",
                    "Educación",
                    "Recreativo",
                    "Abarrotes",
                  ]}
                  title={"Composición del Área"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <LinearChart
                    dataset={dataset}
                    labels={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
                    title={"Vehículos/Hora"}
                    stepSize={stepSize}
                    min={minLine}
                    max={maxLine}
                    legend={false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <LinearChart
                    dataset={dataset_con}
                    labels={[
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                      "13",
                      "14",
                      "15",
                      "16",
                      "17",
                      "18",
                      "19",
                      "20",
                      "21",
                    ]}
                    title={"Congestion/Horas"}
                    stepSize={stepSizeCon}
                    min={minLineCon}
                    max={maxLineCon}
                    legend={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div
              style={{
                height: "900px",
                paddingBottom: "5px",
              }}
            >
              <PremiumMap
                center={{ lat: 25.617847, lng: -100.287275 }}
                zoom={4}
                type={"road"}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Playground;
