import React from "react";
import Swal from "sweetalert2";
import GoogleMapReact from "google-map-react";

let mapa = {};
let mapas = {};
let markers = [];
let sts = [];
let letras = [];
let render = [];
let validation = [];
let aPoint = 0;
let bPoint = 0;
let pin = 0;
let sucTemp = [];

const getAddress = (address) => {
  return new Promise((resolve, reject) => {
    const geocoder = new mapas.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        let location = results[0].geometry.location;
        mapa.setCenter(location);
        mapa.setZoom(15);
        let markerContent = document.createElement("div");
        markerContent.style.width = "32px";
        markerContent.style.height = "32px";
        markerContent.style.backgroundImage = "url('https://maps.gstatic.com/mapfiles/ms2/micons/ylw-pushpin.png')";
        markerContent.style.backgroundSize = "cover";
        let marker = new mapas.Marker({
          position: location,
          draggable: true,
          map: mapa,
          content: markerContent
        });
        marker.setMap(mapa);
        sucTemp.push(marker);
        resolve({ marker, mapas });
      } else {
        reject(status);
      }
    });
  });
};

const markerSucursal = (coords, titulo, ret) => {
  let markerContent = document.createElement("div");
        markerContent.style.width = "32px";
        markerContent.style.height = "32px";
        markerContent.style.backgroundImage = "url('https://maps.gstatic.com/mapfiles/ms2/micons/pink-pushpin.png')";
        markerContent.style.backgroundSize = "cover";
  let marker = new mapas.marker.AdvancedMarkerElement({
    // animation: mapas.Animation.DROP,
    position: coords,
    map: mapa,
    content: markerContent
  });
  const infowindow = new mapas.InfoWindow({
    content: titulo,
  });
  //Si se hace el retorno que se pueda cambiar el color del pin y agregarlo al arreglo
  if (ret) {
    let markerContent = document.createElement("div");
        markerContent.style.width = "32px";
        markerContent.style.height = "32px";
        markerContent.style.backgroundImage = "url('https://maps.gstatic.com/mapfiles/ms2/micons/red-pushpin.png')";
        markerContent.style.backgroundSize = "cover";
    markers.push(marker);
    sts.push(3);
    marker.addListener("click", () => {
      infowindow.open(mapa, marker);
      marker.content = markerContent;
      colorMarker(markers.length - 1);
    });
    marker.setMap(mapa);
    return { marker, mapas };
  } else {
    marker.addListener("click", () => {
      infowindow.open(mapa, marker);
    });
    marker.setMap(mapa);
  }
};

const markerPremium = (coords, status, tipo, orden) => {
  let icono = "";
  let letra = orden ? "B" : "A";
  letras.push(letra);
  /* Cambiar los pines para cuando estemos usando trafico o premium */
  let markerContent = document.createElement("div");
    markerContent.style.width = "32px";
    markerContent.style.height = "32px";
    markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png" + letra +"|6991FD|000000')";
    markerContent.style.backgroundSize = "cover";
  if (tipo === 1) {
    if (status === 1) {
      markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png" + letra +"|6991FD|000000')";  
      // icono = `<div style="width: 32px; height: 32px; background-image: url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png${letra}|6991FD|000000'); background-size: cover;"></div>`
        // "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
        // letra +
        // "|6991FD|000000";
    } else {
      markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png" + letra +"|00E64D|000000')"; 
        // icono = `<div style="width: 32px; height: 32px; background-image: url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png${letra}|00E64D|000000'); background-size: cover;"></div>`
        // "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
        // letra +
        // "|00E64D|000000";
    }
  } else {
    if (status === 1) {
      markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png')"; 
      // icono = `<div style="width: 32px; height: 32px; background-image: url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png'); background-size: cover;"></div>`
        // "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    } else {
      markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/green-dot.png')"; 
      // icono =  `<div style="width: 32px; height: 32px; background-image: url('https://maps.google.com/mapfiles/ms/icons/green-dot.png'); background-size: cover;"></div>`
        // "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
    }
  }

  /* Cambiar los pines para cuando estemos usando trafico o premium */

  let marker = new mapas.marker.AdvancedMarkerElement({
    // animation: mapas.Animation.DROP,
    content: markerContent,
    position: coords,
    map: mapa,
  });
  marker.setMap(mapa);
  markers.push(marker);
  sts.push(status);
  return { marker, mapas };
};

const colorMarker = (id) => {
  let markerContent = document.createElement("div");
    markerContent.style.width = "32px";
    markerContent.style.height = "32px";
    markerContent.style.backgroundSize = "cover";
  for (let i = 0; i < markers.length; i++) {
    if (id !== i) {
      markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/" +(sts[i] === 3
        ? "pink-pushpin.png"
        : sts[i] === 1
        ? "blue-dot.png"
        : "green-dot.png")+"')";
        markers[i].content = markerContent
      // markers[i].setIcon(
      //   "https://maps.google.com/mapfiles/ms/icons/" +
      //     (sts[i] === 3
      //       ? "pink-pushpin.png"
      //       : sts[i] === 1
      //       ? "blue-dot.png"
      //       : "green-dot.png")
      // );
    }
  }
};

const drawPremium = (geoData) => {
  mapa.data.addGeoJson(geoData);
  clearLayer();
};

const clearLayer = () => {
  mapa.data.setStyle({});
  mapa.data.setStyle({
    fillOpacity: 0,
    strokeWeight: 0,
  });
};

const colorLayer = (arrColor, arrLimit, field) => {
  let color = "#bababa";
  clearLayer();
  mapa.data.setStyle(function (feature) {
    let item = feature.getProperty(field);
    if (item < arrLimit[0]) {
      color = arrColor[0];
    } else if (item >= arrLimit[arrLimit.length - 1]) {
      color = arrColor[arrColor.length - 1];
    } else {
      for (let i = 0; i < arrLimit.length - 1; i++) {
        if (item >= arrLimit[i] && item < arrLimit[i + 1]) {
          color = arrColor[i + 1];
        }
      }
    }
    if (item === 0 && field === "Data_NSE") {
      color = "#bababa";
    }
    return {
      fillColor: color,
      fillOpacity: 0.8,
      strokeWeight: 1,
    };
  });
};

const mapUpdate = (map) => {
  if (map === "hibrido") {
    mapa.setMapTypeId(mapas.MapTypeId.HYBRID);
  } else {
    mapa.setMapTypeId(mapas.MapTypeId.ROADMAP);
  }
};

const trafficPins = async () => {
  if (aPoint && bPoint) {
    const request = {
      origin: aPoint.position,
      destination: bPoint.position,
      travelMode: mapas.TravelMode.DRIVING,
      unitSystem: mapas.UnitSystem.METRIC,
      drivingOptions: {
        departureTime: new Date(),
        trafficModel: "bestguess",
      },
    };
    const res = await calculateRoute(request);
    return { aPoint, bPoint, res };
  } else {
    return {};
  }
};

const calculateRoute = (request) => {
  let directionsService = new mapas.DirectionsService();
  return new Promise((resolve, reject) => {
    directionsService.route(request, (result, status) => {
      const obj = result.routes[0].legs[0];
      const segment = obj.distance.value / 1000;
      if (status === "OK") {
        resolve(segment);
      } else {
        resolve(0);
      }
    });
  });
};

const drawPolyline = (coords, status, test) => {
  const color = status === 1 ? "#6991FD" : "#00E64D";
  let directionsService = new mapas.DirectionsService();
  let directionsRenderer = new mapas.DirectionsRenderer({
    suppressMarkers: true,
    preserveViewport: true,
    polylineOptions: {
      strokeColor: color,
      strokeOpacity: 0.5,
      strokeWeight: 5,
      clickable: false,
    },
  });
  const request = {
    origin: coords[0],
    destination: coords[1],
    travelMode: "DRIVING",
  };
  directionsService.route(request, function (result, status) {
    if (status === "OK") {
      directionsRenderer.setDirections(result);
      directionsRenderer.setMap(mapa);
    }
  });

  if (test) {
    render.push(directionsRenderer);
  } else {
    if (validation.length > 0) {
      validation[0].setMap(null);
    }
    validation.push(directionsRenderer);
  }
};

const colorTrafficMarker = (id) => {
  let markerContent = document.createElement("div");
    markerContent.style.width = "32px";
    markerContent.style.height = "32px";
    markerContent.style.backgroundSize = "cover";
  for (let i = 0; i < markers.length; i++) {
    if (id !== i && id + 1 !== i) {
      if (sts[i] === 1) {
        markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
            letras[i] +
            "|6991FD|000000')";
        markers[i].content = markerContent
        // markers[i].setIcon(
        //   "https://maps.google.com/mapfiles/ms/icons/bue-dotl.png" +
        //     letras[i] +
        //     "|6991FD|000000"
        // );
      } else {
        markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
        letras[i] +
        "|00E64D|000000')";
        markers[i].content = markerContent
        // markers[i].setIcon(
        //   "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
        //     letras[i] +
        //     "|00E64D|000000"
        // );
      }
    } else {
      markerContent.style.backgroundImage = "url('https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
      letras[i] +
      "|E65247|000000')";
      markers[i].content = markerContent
      // markers[i].setIcon(
      //   "https://maps.google.com/mapfiles/ms/icons/blue-dot.png" +
      //     letras[i] +
      //     "|E65247|000000"
      // );
    }
  }

  for (let x = 0; x < render.length; x++) {
    if (x === id / 2) {
      render[x].setOptions({
        polylineOptions: {
          strokeColor: "red",
          strokeOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
        },
      });
    } else {
      render[x].setOptions({
        polylineOptions: {
          strokeColor: sts[x * 2] === 1 ? "#6991FD" : "#00E64D",
          strokeOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
        },
      });
    }
    render[x].setMap(null);
    render[x].setMap(mapa);
  }
};

const deleteMarkers = () => {
  if (aPoint) {
    aPoint.setMap(null);
  }
  if (bPoint) {
    bPoint.setMap(null);
  }
  if (validation.length > 0) {
    validation[0].setMap(null);
  }

  aPoint = 0;
  bPoint = 0;
  pin.onPin(0);
};

const deleteSuc = () => {
  sucTemp[0].setMap(null);
  sucTemp = [];
  mapa.setZoom(10);
};

const handleApiLoaded = (map, maps, type, traffic) => {
  mapa = map;
  mapas = maps;
  if (type === "hybrid") {
    mapa.setMapTypeId(mapas.MapTypeId.HYBRID);
  } else {
    mapa.setMapTypeId(mapas.MapTypeId.ROADMAP);
  }
  if (traffic) {
    mapas.event.addListener(map, "rightclick", (e) => {
      if (aPoint && bPoint) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 2000,
        });
        Toast.fire({
          icon: "error",
          title: "No puedes agregar más puntos",
        });
      } else if (aPoint) {
        const pinE = new mapas.marker.PinElement({
          scale: 0.5,
          background: '#FBBC04',
          borderColor: '#000000',
          glyphColor: '#FBBC04',
        });
        bPoint = new mapas.marker.AdvancedMarkerElement({
          position: e.latLng,
          map: mapa,
          draggable: true,
          content: pinE.element
        });
        pin.onPin(1);
      } else {
        const pinE = new mapas.marker.PinElement({
          scale: 0.5,
          background: '#FBBC04',
          borderColor: '#000000',
          glyphColor: '#FBBC04',
        });
        aPoint = new mapas.marker.AdvancedMarkerElement({
          position: e.latLng,
          map: map,
          draggable: true,
          content: pinE.element
        });
      }
    });
  }
};

const PremiumMap = (props) => {
  pin = { ...props };
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY, libraries: ["marker"] }}
      center={props.center}
      zoom={props.zoom}
      options={{
        gestureHandling: "greedy",
        streetViewControl: true,
        
        mapId: 'cebeea4c565952d3 '
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) =>
        handleApiLoaded(map, maps, props.type, props.traffic)
      }
    ></GoogleMapReact>
  );
};

export {
  PremiumMap,
  drawPremium,
  colorLayer,
  clearLayer,
  mapUpdate,
  markerPremium,
  markerSucursal,
  colorMarker,
  trafficPins,
  drawPolyline,
  deleteMarkers,
  deleteSuc,
  colorTrafficMarker,
  getAddress,
};
