import React from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
// import { trackPromise } from "react-promise-tracker";
import { LoadingComponent } from "./loadingComponent";
import { Card, Col, Form, Button, CardBody } from "reactstrap";
import LzForm from "./lzForm";
import { getUserInfoFinancial, updateDataFinance } from "../../services/userService";
import auth from "../../services/authService";
import { getCiudades, getEstados } from "../../services/namesMexico";
import { getRegimenFiscal, getUsoCFDI } from "../../services/sat";
import { trackPromise } from "react-promise-tracker";

class FinancialForm extends LzForm {

    state = {
    data: {
        razonSocial: "",
        rfc: "",
        direccion: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        estado: "",
        ciudad: "",
        telefono: "",
        correo: "",
        regimenFiscalSAT: "",
        codigoPostal: "",
        usoCFDI: "",
        codigoPostal: ""
    },
    estados: [],
    ciudades: [],
    regimenFiscal: [],
    usoCFDI: [],
    errors: {},
    acceptPolicy: false,
    };

    schema = {
        razonSocial: Joi.string().empty('').optional().label("Razón Social"),
        rfc: Joi.string().empty('').optional().label("R.F.C"),
        direccion: Joi.string().empty('').optional().label("Dirección"),
        numeroExterior: Joi.string().empty('').optional().label("Número Exterior"),
        numeroInterior: Joi.string().empty('').optional().label("Número Interior"),
        colonia: Joi.string().empty('').optional().label("Colonia"),
        estado: Joi.string().empty('').optional().label("Estado"),
        ciudad: Joi.string().empty('').optional().label("Ciudad"),
        telefono: Joi.string().empty('').optional().label("Telefono"),
        correo: Joi.string().empty('').optional().label("Correo"),
        regimenFiscalSAT: Joi.string().empty('').optional().label("Régimen Fiscal"),
        codigoPostal: Joi.string().empty('').optional().label("Código Postal"),
        usoCFDI: Joi.string().empty('').optional().label("Uso CFDI"),
    };

    async componentDidMount() {
        const { id: userId } = auth.getCurrentUser();
        const { data } = await getUserInfoFinancial(userId);
        const { data: estados } = await getEstados();
        let ciudades = []
        if (data[0].ciudad !== '') {
            const { data: ciudadesN } = await getCiudades(data[0].estado);
            ciudades = ciudadesN;
        }
        const { data: regimenFiscal } = await getRegimenFiscal();
        const { data: usoCFDI } = await getUsoCFDI();
        const { updateData } = this.props;
        
        if (updateData && data[0]) {
            this.setState({ data:data[0], estados, regimenFiscal, usoCFDI, ciudades });
        } else {
            this.setState({ estados, regimenFiscal, usoCFDI });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const errors = this.validate();
        this.setState({ errors: errors || {} });
        
        if (errors) {
            return;
        }

        this.doSubmit();
    };

    doSubmit = async () => {
        try {
            const { data } = this.state;
            const { updateData } = this.props;
            if (updateData) {
                const { id } = auth.getCurrentUser();
                data["id"] = id;
                const response = await trackPromise(updateDataFinance(data));
                if (response.status === 200) {
                    Swal.fire(
                        "¡Cambios Guardados!",
                        "",
                        "success"
                    );
                    this.props.onUpdate(data);
                }
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.usuario = ex.response.data;
                this.setState({ errors });
            }
        }
    };

    render() {
        const { updateData } = this.props;
        return (
            <Col md="12">
                {" "}
                <Card>
                    <LoadingComponent />
                    <h3 className="text-center">
                        <b>{updateData ? "Modifica tus datos" : "Ingresa tus datos"}</b>
                    </h3>
                    <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-4">
                                    {this.renderInput("razonSocial", "Razón Social", "text", "Ej. PepsiCo, Inc.")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderInput("rfc", "R.F.C", "text", "Ej. VECJ880326.")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderInput("direccion", "Dirección", "text", "Ej. Carr. Monterrey - Saltillo 7935, Jardines Coloniales, 25206 Saltillo, Coah.")}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    {this.renderInput("numeroExterior", "Número Exterior", "number", "Ej. 123.")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderInput("numeroInterior", "Número Interior", "number", "Ej. 456.")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderInput("cp", "Código Postal", "number", "Ej. 98200.")}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    {this.renderInput("colonia", "Colonia", "text", "Ej. San Patricio.")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderSelect("estado", "Estado", this.state.estados, 1)}
                                </div>
                                <div className="col-md-4">
                                    {this.renderSelect("ciudad", "Ciudad", this.state.ciudades)}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    {this.renderInput("telefono", "Telefono", "text", "Ej. 123-456789-1911")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderInput("correo", "Correo", "text", "Ej. info@capitolcity2.com")}
                                </div>
                                <div className="col-md-4">
                                    {this.renderSelect("regimenFiscalSAT", "Régimen Fiscal", this.state.regimenFiscal, 1)}
                                </div>
                            </div>
                            <div className="row mt-2 h-100 d-flex justify-content-center align-items-center">
                                <div className="col-md-4">
                                    {this.renderSelect("usoCFDI", "Uso CFDI", this.state.usoCFDI, 1)}
                                </div>
                                <div className="col-md-8">
                                    <Button
                                        block
                                        className="btn-round mt-2"
                                        color="danger"
                                        type="submit"
                                    >
                                        Guardar Cambios
                                    </Button>
                                </div>
                            </div>
                        </Form>
                {/* <Form onSubmit={this.handleSubmit}>
                    {this.renderInput("nombre", "Nombre", "text", "Ej. Pedro")}
                    {this.renderInput("apellido", "Apellido", "text", "Ej. Perez")}
                    {this.renderInput(
                        "usuario",
                        "Email",
                        "text",
                        "Ej. bigducktech@gmail.com"
                    )}
                    {updateData
                        ? null
                        : this.renderInput("password", "Password", "password")}
                    {this.renderInput(
                        "telefono",
                        "Telefono",
                        "text",
                        "Ej. 123-456789-1911"
                    )}
                    {updateData ? null : (
                        <div className="form-check text-center mb-1">
                        <label className="form-check-label">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={this.state.acceptPolicy}
                            onChange={this.handleCheck}
                            />
                            <span className="form-check-sign">
                            <span className="check"></span>
                            </span>
                            <a
                            href="https://fragoso09.github.io/capcitydocs.github.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}
                            >
                            <b>
                                <u>Aceptar Terminos y Condiciones</u>
                            </b>
                            </a>
                        </label>
                        </div>
                    )}
                    {!updateData && (
                        <Button
                        block
                        className="btn-round btn-danger mt-2"
                        type="submit"
                        disabled={!this.state.acceptPolicy}
                        >
                        Crear Usuario
                        </Button>
                    )}
                    {updateData && (
                        <Button
                        block
                        className="btn-round mt-2"
                        color="danger"
                        type="submit"
                        >
                        Guardar Cambios
                        </Button>
                    )}
                    </Form>
                    {!updateData && (
                    <NavLink
                        href="#"
                        className="text-center mt-1 text-primary"
                        onClick={this.props.onProcessChg}
                    >
                        {" "}
                        <h6>Iniciar Sesión</h6>
                    </NavLink>
                    )} */}
                    </CardBody>
                </Card>
            </Col>
          
        );
      }

}


export default FinancialForm;

