import React, { Component } from "react";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import auth from "../services/authService";
import IndexNavbar from "./indexNavbar";
import LzPagination from "./common/lzPagination";
import LzCard from "./common/lzCard";
import ProfileCol from "./common/profileCol";
import InboxList from "./common/inboxList";
import RegisterForm from "./common/registerForm";
import TableLz from "./common/tableLz";
import ProfileFilter from "./common/profileFilter";
import PassModal from "./common/passModal";
import { LoadingComponent } from "./common/loadingComponent";
import { getContacto } from "../services/contactoService";
import { sendEmail } from "../services/emailService";
import { getFavorito } from "../services/favoritoService";
import { getInbox } from "../services/inboxService";
import { getCartera } from "../services/carteraService";
import { getUserInfo, getUserInfoFinancial } from "../services/userService";
import { paginate } from "./../utils/paginate";
import FinancialForm from "./common/financialForm";

class ProfileUser extends Component {
  state = {
    data: {
      usuario: "",
      nombre: "",
      apellido: "",
      telefono: "",
      password: "",
      propsact: 0,
      propsreg: 0,
    },
    inbox: [],
    propiedades: [],
    favoritos: [],
    currentPage: 1,
    currentPageInbox: 1,
    pageSize: 2,
    pageSizeInbox: 3,
    modificarDatos: false,
    modificarDatosFiscales: false,
    cartera: false,
    contacto: true,
    carteraInfo: [],
    carteraFilter: [],
    modal: false,
  };

  async componentDidMount() {
    const { id: userId } = auth.getCurrentUser();
    const { data: favoritos } = await trackPromise(getFavorito(userId));
    const { data: propiedades } = await getContacto(userId);
    const { data: inbox } = await getInbox(userId);
    const { data: carteraInfo } = await getCartera(userId);
    const { data } = await getUserInfo(userId);
    data[0]["password"] = "xxxx";
    this.setState({
      favoritos,
      propiedades,
      inbox: inbox[0],
      carteraInfo: carteraInfo[0],
      carteraFilter: carteraInfo[0],
      data: data[0],
    });
  }

  handleContact = async (body, accion) => {
    const result = await trackPromise(sendEmail(body));
    if (result.status === 200) {
      Swal.fire(
        accion === "contactar" ? "¡Contactado!" : "¡Rechazado!",
        accion === "contactar"
          ? "¡Espera la llamada o correo de tu cliente!"
          : "Enviamos el mensaje al usuario",
        "success"
      );
      const { id: userId } = auth.getCurrentUser();
      const { data: inbox } = await getInbox(userId);
      this.setState({ inbox: inbox[0] });
    }
  };

  handleCartera = () => {
    const cartera = this.state.cartera;
    this.setState({ cartera: !cartera });
    if (cartera) {
      const carteraFilter = this.state.carteraInfo;
      this.setState({ carteraFilter });
    }
  };

  handleShowContacto = () => {
    const contacto = !this.state.contacto;
    this.setState({ contacto });
  };

  hanldeUpdate = (user) => {
    this.state.modificarDatosFiscales = false;
    const modificar = this.state.modificarDatos;
    if (user) {
      this.setState({ modificarDatos: !modificar, data: user });
    } else {
      this.setState({ modificarDatos: !modificar });
      if (this.state.cartera) {
        this.setState({ cartera: false });
      }
    }
  };

  hanldeUpdateFiscales = (user) => {
    this.state.modificarDatos = false;
    const modificar = this.state.modificarDatosFiscales;
    if (user) {
      this.setState({ modificarDatosFiscales: !modificar });
    } else {
      this.setState({ modificarDatosFiscales: !modificar });
      if (this.state.cartera) {
        this.setState({ cartera: false });
      }
    }
  };

  handleFilter = (filter) => {
    this.setState({ carteraFilter: filter });
  };

  handleModal = () => {
    const modal = this.state.modal;
    this.setState({ modal: !modal });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageChangeInbox = (page) => {
    this.setState({ currentPageInbox: page });
  };

  getPagedData = () => {
    const {
      currentPage,
      pageSize,
      currentPageInbox,
      pageSizeInbox,
      favoritos,
      inbox,
      propiedades,
    } = this.state;
    const resFav = paginate(favoritos, currentPage, pageSize);
    const resProps = paginate(propiedades, currentPage, pageSize);
    const resInbox = paginate(inbox, currentPageInbox, pageSizeInbox);
    return {
      totalCountFav: favoritos.length,
      totalCountProps: propiedades.length,
      totalCountInbox: inbox.length,
      resInbox,
      resFav,
      resProps,
    };
  };

  render() {
    const {
      totalCountFav,
      totalCountInbox,
      totalCountProps,
      resInbox,
      resFav,
      resProps,
    } = this.getPagedData();
    const {
      pageSize,
      pageSizeInbox,
      currentPage,
      currentPageInbox,
      data,
      modificarDatos,
      modificarDatosFiscales,
      cartera,
      carteraFilter,
      carteraInfo,
      modal,
      contacto,
    } = this.state;

    return (
      <React.Fragment>
        <IndexNavbar clase="sticky-top" user={this.props.user} />
        <PassModal
          modal={modal}
          onToggle={this.handleModal}
          user={this.props.user}
        />
        <LoadingComponent />
        <div
          className=""
          style={{
            paddingRight: "5px",
            paddingLeft: "5px",
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px",
          }}
        >
          <div className="row">
            <div className="col-md-3">
              <ProfileCol
                data={data}
                modificar={modificarDatos}
                onUpdate={this.hanldeUpdate}
                onCartera={this.handleCartera}
                cartera={cartera}
                contacto={contacto}
                onContacto={this.handleShowContacto}
                modal={this.handleModal}
                modificarDatosFiscales={modificarDatosFiscales}
                onUpdateFiscales={this.hanldeUpdateFiscales}
              />
            </div>
            
            {modificarDatos && (
              <React.Fragment>
                <div className="col-md-4">
                  <RegisterForm
                      updateData={1}
                      data={data}
                      onUpdate={this.hanldeUpdate}
                    />
                </div>              
              </React.Fragment>
            )}
            {modificarDatosFiscales && (
              <React.Fragment>
                <div className="col-md-9">
                  <FinancialForm
                    updateData={1}
                    data={{}}
                    onUpdate={this.hanldeUpdateFiscales}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileUser;
