import React from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { LoadingComponent } from "./loadingComponent";
import { Modal } from "reactstrap";
import LzForm from "./lzForm";
import { forgotPass } from "../../services/userService";

class ForgotPassModal extends LzForm {
  state = {
    data: { usuario: "" },
    errors: {},
  };

  schema = {
    usuario: Joi.string().required().email().label("Usuario"),
  };

  handleToggle = () => {
    this.props.onToggle();
  };

  doSubmit = async () => {
    try {
      const res = await trackPromise(
        forgotPass({ usuario: this.state.data.usuario })
      );
      if (res.status === 200) {
        Swal.fire(
          "¡Listo!",
          "Revisa tu correo para recuperar tu contraseña",
          "success"
        );
        const data = { ...this.state.data };
        data["usuario"] = "";
        this.setState({ data });
        this.props.onToggle();
      }
    } catch (err) {
      Swal.fire("¡Ups!", `No encontramos este usuario registrado: ${err}`, "error");
    }
  };

  render() {
    const { modal } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={modal} toggle={this.handleToggle} className="">
          <LoadingComponent />
          <div className="modal-body p-1">
            <div className="row p-1">
              <div className="col-12 text-center">
                <h5 style={{ margin: "5px 0" }}>
                  <b>Recupera tu contraeña</b>
                </h5>
              </div>
              <div className="col mt-2 text-center">
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "usuario",
                    "Correo",
                    "text",
                    "Correo Registrado"
                  )}
                  <button className="btn btn-round btn-danger" type="submit">
                    Recuperar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ForgotPassModal;
