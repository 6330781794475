import React from "react";
import GoogleMapReact from "google-map-react";
import PropertyPin from "./propertyPin";

let mapa = {};
let mapas = {};

const drawMarkers = (propiedades, hoverId, userId) => {
  if (!propiedades[0]) return null;
  const propertyPins = propiedades.map((pty) => {
    if (pty.lat === null || pty.lng === null) {
      return null;
    } else {
      return (
        <PropertyPin
          key={pty.id}
          lat={pty.lat}
          lng={pty.lng}
          precio={pty.precio}
          metros={pty.metros}
          colonia={pty.colonia}
          fotos={pty.fotos}
          id={pty.id}
          usuario={pty.usuario_id}
          hoverId={hoverId}
          actualUserId={userId}
        />
      );
    }
  }); //Mapeo de propiedades
  return propertyPins;
};

const getAddress = (address) => {
  return new Promise((resolve, reject) => {
    const geocoder = new mapas.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        let location = results[0].geometry.location;
        mapa.setCenter(location);
        const zoom = results[0].types[0] === "locality" ? 13 : 7;
        mapa.setZoom(zoom);
        resolve(status);
      } else {
        reject(status);
      }
    });
  });
};

const handleApiLoaded = (map, maps) => {
  mapa = map;
  mapas = maps;
  mapa.setMapTypeId(mapas.MapTypeId.HYBRID);
};

const MapContainer = ({ center, propiedades, hoverId, userId }) => {
  const defaultProps = {
    center: { lat: 25.617544, lng: -100.287534 },
    zoom: 11,
  };

  const setCenter =
    center === "" || center === undefined ? this.state.center : center[0];

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY, libraries: ["marker"] }}
      defaultCenter={defaultProps.center}
      center={setCenter}
      defaultZoom={defaultProps.zoom}
      options={{
        gestureHandling: "greedy",
        streetViewControl: true,
        
        mapId: 'cebeea4c565952d3 '
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      {drawMarkers(propiedades, hoverId, userId)}
    </GoogleMapReact>
  );
};

export { MapContainer, getAddress, drawMarkers };
