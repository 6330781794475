import http from "./httpService";

export function createUser(usuario) {
  const body = { ...usuario };
  body["nombre"] = titleCase(body["nombre"]);
  body["apellido"] = titleCase(body["apellido"]);
  body["usuario"] = body["usuario"].trim();
  body["password"] = body["password"].trim();
  return http.post("/users", body);
}

export function forgotPass(usuario) {
  return http.post("/users/pass", usuario);
}

export function updatePass(body) {
  body["newP"] = body["newP"].trim();
  return http.put("/users", body);
}

export function updateUser(usuario) {
  const body = { ...usuario };
  delete body.id;
  body["nombre"] = titleCase(body["nombre"]);
  body["apellido"] = titleCase(body["apellido"]);
  body["usuario"] = body["usuario"].trim();
  return http.put("/users/" + usuario.id, body);
}

export function getUserInfo(userId) {
  return http.get("/users/" + userId);
}

export function getUserInfoFinancial(userId) {
  return http.get("/users/dataFinance/" + userId);
}

export function updateDataFinance(body) {
  return http.post("/users/updateDataFinance/" + body.id, body)
}

function titleCase(str) {
  str = str.trim();
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}
